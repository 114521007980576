<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <MultiTermsTab
          v-slot:default="slotProps"
          :tabs="$options.tabs">
          <keep-alive>
            <component :is="slotProps.tabComponent || $options.tabs.subscription.componentName"/>
          </keep-alive>
        </MultiTermsTab>
      </column>
    </row>
  </div>
</template>
<script>
import { Page, MultiTermsTab } from 'global-components';
import { DigitalTerms } from './tabs';

export default {
  name: 'terms-page',
  components: {
    MultiTermsTab,
    /* eslint-disable vue/no-unused-components */
    DigitalTerms,
    /* eslint-enable vue/no-unused-components */
  },
  extends: Page,
  data() {
    return {
      tabComponent: DigitalTerms.name
    };
  },
  tabs: {
    digital: {
      title: 'Digital',
      componentName: DigitalTerms.name
    },
  },
};
</script>
